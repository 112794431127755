import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { dateForm } from "../../../helpers";
import {
  Amount,
  AmountProperty,
  AmountWrapper,
  BookingTime,
  Discount,
  DropLocation,
  Due,
  JourneyDetails,
  JourneyDetailsProperty,
  JourneyTime,
  Luggage,
  LuggageFirst,
  PickLocation,
  Seat,
  SeatAndAmount,
  SeatNumber,
  SeatProperty,
  Time,
  TimeProperty,
  Total,
  TotalTax,
  Wrapper,
} from "./TicketTrackingBody.style";
import { convertTo12HourFormat } from "../../../utils/time24converter";

const TicketTrackingBody = ({ ticketTracking }) => {
  const { webSettingData, languageData } = useSelector(
    (state) => state.busLists
  );

  console.log(webSettingData?.luggage_service);

  const bookingTime =
    ticketTracking?.created_at &&
    convertTo12HourFormat(ticketTracking.created_at.split(" ")[1]);

  const [boardingPoint, setBoardingPoint] = useState(null);
  const [droppingPoint, setDroppingPoint] = useState(null);
  const [standLists, setStandLists] = useState(null);

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API_MODULE_DOMAIN}/triplist/boardings/${ticketTracking?.trip_id}`
    )
      .then((res) => res.json())
      .then((data) => setBoardingPoint(data.data));

    fetch(
      `${process.env.REACT_APP_API_MODULE_DOMAIN}/triplist/droppings/${ticketTracking?.trip_id}`
    )
      .then((res) => res.json())
      .then((data) => setDroppingPoint(data.data));

    fetch(`${process.env.REACT_APP_API_MODULE_DOMAIN}/stands`)
      .then((res) => res.json())
      .then((data) => setStandLists(data.data));
  }, [ticketTracking]);

  // useEffect(() => {
  //   fetch(`${process.env.REACT_APP_API_MODULE_DOMAIN}/localize/strings`)
  //     .then((res) => res.json())
  //     .then((data) => setLanguageData(data.data));
  // }, []);

  const PicktandName = (id) => {
    const findNumber = boardingPoint?.find((item) => item?.id === id);
    const findName = standLists?.find(
      (item) => item?.id === findNumber?.stand_id
    );
    return findName?.name;
  };
  const dropStandName = (id) => {
    const findNumber = droppingPoint?.find((item) => item?.id === id);
    const findName = standLists?.find(
      (item) => item?.id === findNumber?.stand_id
    );
    return findName?.name;
  };

  return (
    <Wrapper>
      <JourneyDetails>
        <PickLocation>
          <JourneyDetailsProperty>
            {
              languageData?.ticket_traking_page_pick_laction[
                webSettingData?.language
              ]
            }{" "}
          </JourneyDetailsProperty>
          : {PicktandName(ticketTracking?.pick_stand_id)}
        </PickLocation>
        <DropLocation>
          <JourneyDetailsProperty>
            {
              languageData?.ticket_traking_page_drop_laction[
                webSettingData?.language
              ]
            }{" "}
          </JourneyDetailsProperty>
          : {dropStandName(ticketTracking?.drop_stand_id)}
        </DropLocation>
      </JourneyDetails>

      <Time>
        <BookingTime>
          <TimeProperty>
            {
              languageData?.ticket_traking_page_booking_date[
                webSettingData?.language
              ]
            }
          </TimeProperty>
          : {dateForm(ticketTracking?.created_at)}{" "}
        </BookingTime>
        <JourneyTime>
          <TimeProperty>
            {
              languageData?.ticket_traking_page_journey_date[
                webSettingData?.language
              ]
            }
          </TimeProperty>
          : {dateForm(ticketTracking?.journeydata)}
        </JourneyTime>
      </Time>

      <div style={{ marginTop: "10px" }}>
        <Time>
          <BookingTime>
            <TimeProperty>
              {languageData?.booking_time
                ? languageData?.booking_time[webSettingData?.language]
                : "booking_time"}
            </TimeProperty>
            : {bookingTime && bookingTime}
          </BookingTime>
          <JourneyTime>
            <TimeProperty>
              {languageData?.journey_start_time
                ? languageData?.journey_start_time[webSettingData?.language]
                : "journey_start_time"}
            </TimeProperty>
            : {ticketTracking?.trip_start_time}
          </JourneyTime>
        </Time>
      </div>
      {webSettingData?.luggage_service && ticketTracking?.special_luggage && (
        <SeatAndAmount>
          <Seat>
            <SeatProperty>
              {languageData?.special_luggage
                ? languageData?.special_luggage[webSettingData?.language]
                : "special_luggage"}
            </SeatProperty>
          </Seat>
          <AmountWrapper>
            <Amount>{ticketTracking.special_luggage}</Amount>
          </AmountWrapper>
        </SeatAndAmount>
      )}
      <SeatAndAmount>
        <Seat>
          <SeatProperty>
            {
              languageData?.ticket_traking_page_seat_number[
                webSettingData?.language
              ]
            }
          </SeatProperty>

          <SeatNumber> {ticketTracking?.seatnumber}</SeatNumber>
        </Seat>
        <AmountWrapper>
          <AmountProperty>
            {
              languageData?.ticket_traking_page_ammount[
                webSettingData?.language
              ]
            }
          </AmountProperty>

          <Amount>
            {" "}
            {webSettingData?.currency_symbol}
            {ticketTracking?.price}
          </Amount>
        </AmountWrapper>
      </SeatAndAmount>
      {ticketTracking?.payment_status == "paid" &&
        ticketTracking?.pay_type_id == "3" && (
          <SeatAndAmount>
            <Seat>
              <SeatProperty>
                {languageData?.payment_gateway
                  ? languageData?.payment_gateway[webSettingData?.language]
                  : "payment_gateway"}
              </SeatProperty>
            </Seat>
            <AmountWrapper>
              <Amount>{ticketTracking.payment_detail}</Amount>
            </AmountWrapper>
          </SeatAndAmount>
        )}
      {webSettingData?.luggage_service && (
        <>
          <LuggageFirst>
            <div>
              {languageData?.total_paid_luggage_fee
                ? languageData?.total_paid_luggage_fee[webSettingData?.language]
                : "total_paid_luggage_fee"}
              ({ticketTracking?.paid_max_luggage_pcs} Pcs)
            </div>
            <div>
              {webSettingData?.currency_symbol}
              {ticketTracking?.total_paid_luggage_price}
            </div>
          </LuggageFirst>
          <Luggage>
            <div>
              {languageData?.total_special_luggage_fee
                ? languageData?.total_special_luggage_fee[
                    webSettingData?.language
                  ]
                : "total_special_luggage_fee"}
              ({ticketTracking?.special_max_luggage_pcs} Pcs)
            </div>
            <div>
              {webSettingData?.currency_symbol}
              {ticketTracking?.total_special_luggage_price}
            </div>
          </Luggage>
        </>
      )}
      <Luggage>
        <div>
          {languageData?.sub_total
            ? languageData?.sub_total[webSettingData?.language]
            : "sub_total"}
        </div>
        <div>
          {webSettingData?.currency_symbol}
          {ticketTracking?.sub_total}
        </div>
      </Luggage>

      <Discount>
        <div>
          {languageData?.ticket_traking_page_discount[webSettingData?.language]}
        </div>
        <div>
          {webSettingData?.currency_symbol}
          {ticketTracking?.discount}
        </div>
      </Discount>

      <TotalTax>
        <div>
          {
            languageData?.ticket_traking_page_total_tax[
              webSettingData?.language
            ]
          }
        </div>
        <div>
          {webSettingData?.currency_symbol}
          {ticketTracking?.totaltax}
        </div>
      </TotalTax>
      <Luggage>
        <div>
          {languageData?.grand_total
            ? languageData?.grand_total[webSettingData?.language]
            : "grand_total"}{" "}
          {ticketTracking?.totaltax == 0 &&
            `(${
              languageData?.tax_included
                ? languageData?.tax_included[webSettingData?.language]
                : "tax_included"
            })`}
        </div>
        <div>
          {webSettingData?.currency_symbol}
          {ticketTracking?.grand_total}
        </div>
      </Luggage>
      {ticketTracking?.payment_status == "partial" && (
        <>
          <Luggage>
            <div>
              {languageData?.paid_amount
                ? languageData?.paid_amount[webSettingData?.language]
                : "paid_amount"}
            </div>
            <div>
              {webSettingData?.currency_symbol}
              {ticketTracking?.paidamount}
            </div>
          </Luggage>
          <Luggage>
            <div>
              {languageData?.due
                ? languageData?.due[webSettingData?.language]
                : "due"}
            </div>
            <div>
              {webSettingData?.currency_symbol}
              {Number(ticketTracking?.grand_total) -
                Number(ticketTracking?.paidamount)}
            </div>
          </Luggage>
        </>
      )}
      {ticketTracking?.payment_status == "paid" && (
        <>
          <Luggage>
            <div>
              {languageData?.paid_amount
                ? languageData?.paid_amount[webSettingData?.language]
                : "paid_amount"}
            </div>
            <div>
              {webSettingData?.currency_symbol}
              {ticketTracking?.paidamount}
            </div>
          </Luggage>
        </>
      )}

      {ticketTracking?.payment_status === "unpaid" && (
        <Due>
          <div>
            {languageData?.ticket_traking_page_due[webSettingData?.language]}
          </div>
          <div>
            {webSettingData?.currency_symbol}
            {ticketTracking?.payment_status === "unpaid"
              ? ticketTracking?.grand_total
              : ticketTracking?.price -
                Number(ticketTracking?.discount) +
                Number(ticketTracking?.totaltax)}
          </div>
        </Due>
      )}
    </Wrapper>
  );
};

export default TicketTrackingBody;
